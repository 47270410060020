import logo from "../../logo.png";
import React from "react";
import {NavLink} from "react-router-dom";


export const Dashboard = key => {

    return (
        <>
        <div className="text-right">
            <button className="btn btn-danger" onClick={() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('user')
                    window.location.href="/"

            }}>Logout</button>
            <img src={JSON.parse(localStorage.getItem('user')).profilePicURL} alt="Profil" className="rounded-circle ml-3" width={65}/>
        </div>
        <div className="text-center mt-3">
            <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
            <h3 className="mt-3">Dashboard Content<br/>Manajemen Ayoboga</h3>
        </div>
        <div className="row justify-content-center text-center">
            <div className="col-md-4 m-2 p-3 rounded shadow bg-warning">
                <h4>Manage Berita</h4>
                <NavLink to="/dashboard/news" className="mt-3 btn btn-primary">Manage</NavLink>
            </div>
            <div className="col-md-4 m-2 p-3 rounded shadow bg-primary text-white">
                <h4>Manage Menu Belajar</h4>
                <NavLink to="/dashboard/menus" className="mt-3 btn btn-warning">Manage</NavLink>
            </div>
            <div className="col-md-4 m-2 p-3 rounded shadow bg-danger text-white">
                <h4>Manage Materi Belajar</h4>
                <NavLink to="/dashboard/materials" className="mt-3 btn btn-info">Manage</NavLink>
            </div>
            <div className="col-md-4 m-2 p-3 rounded shadow bg-dark text-white">
                <h4>Manage Quiz</h4>
                <NavLink to="/dashboard/quizzes" className="mt-3 btn btn-secondary">Manage</NavLink>
            </div>
        </div>

    </>
    )
}
