import {NavLink} from "react-router-dom";
import React from "react";

export default function NavDashboard() {
    return (
        <div className="text-right">
            <NavLink to="/dashboard" className="btn btn-primary mr-2">Kembali ke Dashboard</NavLink>
            <button className="btn btn-danger" onClick={() => {
                window.confirm('Mau Keluar?')
                localStorage.removeItem('token')
                localStorage.removeItem('user')
                window.location.href="/"

            }}>Logout</button>
            <img src={JSON.parse(localStorage.getItem('user')).profilePicURL} alt="Profil" className="rounded-circle ml-3" width={65}/>
        </div>
    )
}