import logo from "../../logo.png";
import React, {useEffect, useState} from "react";
import NavDashboard from "../../components/NavDashboard";
import axios from "axios";
import slugify from 'react-slugify';


export const Menus = () => {

    const [cooks, setCooks] = useState(null);
    const [input, setInput] = useState({
        title: null,
        desc: null,
        slug:null
    });
    const [statusForm, setStatusForm] = useState("Create");
    const [selectedId, setSelectedId] = useState(null);
    const [search, setSearch] = useState(null);

    const token = JSON.parse(localStorage.getItem("JWT"))

    useEffect(() => {
        if (cooks === null) {
            axios
                .get("https://ayobogabackend.herokuapp.com/api/v1/visitor/menus")
                .then((res) => {
                    setCooks(
                        res.data.data.map((el) => {
                            return {
                                id: el.ID,
                                title: el.Title,
                                desc: el.Desc,
                                slug: el.Slug,
                            };
                        })
                    );
                });
        }
    }, [cooks]);


    const handleChange = (event) => {
        let typeOfInput = event.target.name;

        // eslint-disable-next-line default-case
        switch (typeOfInput) {
            case "title": {
                setInput({...input, title: event.target.value, slug: slugify(event.target.value)});
                break;
            }
            case "desc": {
                setInput({...input, desc: event.target.value})
                break;
            }
        }
    }
    const HandlerEdit = id => {
        let singleMenus = cooks.find((x) => x.id === id);
        setInput({
            title: singleMenus.title,
            desc: singleMenus.desc,
            slug: singleMenus.slug,
        });
        setSelectedId(id);
        setStatusForm("Edit");
    };


    const handleSubmit = (event) => {
        // menahan submit
        event.preventDefault();

        const params = new URLSearchParams()
        params.append('title', input.title)
        params.append('desc', input.desc)

        if (statusForm === "Create") {
            axios
                .post(`https://ayobogabackend.herokuapp.com/api/v1/admin/menus`, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization' : `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setCooks([...cooks, {id: res.data.id, ...input}]);
                    window.location.reload();
                }).catch(err => console.log(err));
        } else if (statusForm === 'Edit') {
            axios.put(`https://ayobogabackend.herokuapp.com/api/v1/admin/menus/update/${selectedId}`, params,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : `Bearer ${token}`
                }
            }).then((res) => {
                setCooks([...cooks, {id: res.data.id, ...input}]);
                window.location.reload();
            }).catch(err => console.log(err));
        }
    }

    const HandlerDelete = id => {
        let newCook = cooks.filter(el => el.id !== id)
        axios.delete(`https://ayobogabackend.herokuapp.com/api/v1/admin/menus/delete/${id}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
            .then(res => {
            }).catch(err => console.log(err));
        setCooks([...newCook])

    }
    function submitSearch(e) {
        e.preventDefault()
        axios
            .get("https://ayobogabackend.herokuapp.com/api/v1/visitor/menus")
            .then((res) => {
                let restMenu =  res.data.data.map((el) => {
                        return {
                            id: el.ID,
                            title: el.Title,
                            desc: el.Desc,
                            slug: el.Slug,
                        };
                    })
                let filterMenu= restMenu.filter(
                    (x) => x.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
                );
                setCooks([...filterMenu]);
            })
    }

    function handleChangeSearch(event) {
        setSearch(event.target.value);
    }

    return (
        <>
        <NavDashboard/>
        <div className="text-center mt-3">
            <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
            <h3 className="mt-3">Manage Menu Ayoboga</h3>
        </div>

        <form className="col-md-7 my-4" onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group">
                <label htmlFor="judul">Judul Menu</label>
                <input type="text" className="form-control" id="judul" name="title"
                       value={input.title} onChange={handleChange}
                       placeholder="Masukin Judul Menu.."/>
            </div>
            <div className="form-group">
                <label htmlFor="slug">Slug Menu</label>

                {statusForm === "Edit" ?
                    (<>
                        <input type="text" className="form-control" id="slug" name="slug"
                               value={input.slug} aria-describedby="slug" disabled={true}
                               placeholder="Masukin Slug Menu.."/>
                        <div id="slug" className="text-danger mt-2">Slug diform berubah, namun tidak bisa diganti database.</div>
                    </>): <input type="text" className="form-control" id="slug" name="slug"
                                 value={input.slug} aria-describedby="slug"
                                 placeholder="Masukin Slug Menu.."/>}
            </div>
            <div className="form-group">
                <label htmlFor="content">Konten</label>
                <input type="text" className="form-control" id="desc" name="desc"
                       value={input.desc} onChange={handleChange}
                       placeholder="Masukin Deksripsi Menu.."/>
            </div>
            <button type="submit" className="btn btn-primary">{statusForm}</button>
        </form>

        <form className="col-md-7 my-4 mt-5" encType="multipart/form-data" onSubmit={submitSearch}>
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Cari berdasarkan Pertanyaan"
                       aria-label="Cari berdasarkan Judul" aria-describedby="button-addon2" onChange={handleChangeSearch}/>
            </div>
        </form>

        <table className="table table-striped table-hover mt-4">
            <tr>
                <th scope="col">No</th>
                <th scope="col">Judul</th>
                <th scope="col">Deskripsi</th>
                <th scope="col">Slug</th>
                <th scope="col">Aksi</th>
            </tr>
            {
                cooks && cooks.map((cook, index) => (
                    <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{cook.title}</td>
                        <td>{cook.desc.substring(0, 50)}</td>
                        <td>{cook.slug}</td>
                        <td>
                            <button className="btn btn-sm btn-primary m-1" onClick={()=> HandlerEdit(cook.id)}>Edit</button>
                            <button className="btn btn-sm btn-danger m-1" onClick={() => {
                                alert("Hapus?")
                                HandlerDelete(cook.id)
                            }}>Hapus</button>
                        </td>
                    </tr>
                ))
            }
        </table>

    </>
    )
}
