import logo from "../../logo.png";
import React, {useEffect, useState} from "react";
import NavDashboard from "../../components/NavDashboard";
import axios from "axios";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export const Materials = () => {
    const [materials, setMaterials] = useState(null);
    const [cooks, setCooks] = useState(null);
    const [input, setInput] = useState({
        id_menu: null,
        title: null,
        subTitle: null,
        content:null
    });
    const [statusForm, setStatusForm] = useState("Create");
    const [selectedId, setSelectedId] = useState(null);
    const [search, setSearch] = useState(null);


    const token = JSON.parse(localStorage.getItem("JWT"))


    useEffect(() => {
        if (materials === null) {
            axios
                .get(
                    `https://ayobogabackend.herokuapp.com/api/v1/visitor/materials`
                )
                .then((res) => {
                    setMaterials(
                        res.data.data.map((el) => {
                            return {
                                id: el.ID,
                                id_menu: el.IDMenu,
                                title: el.Title,
                                subTitle: el.SubTitle,
                                content: el.Content,
                            };
                        })
                    )
                })
        }

        if (cooks === null) {
            axios
                .get("https://ayobogabackend.herokuapp.com/api/v1/visitor/menus")
                .then((res) => {
                    setCooks(
                        res.data.data.map((el) => {
                            return {
                                id: el.ID,
                                title: el.Title,
                                desc: el.Desc,
                                slug: el.Slug,
                            };
                        })
                    );
                });
        }

    }, [cooks, materials]);

    const handleChange = (event) => {
        let typeOfInput = event.target.name;

        // eslint-disable-next-line default-case
        switch (typeOfInput) {
            case "id_menu": {
                setInput({...input, id_menu: event.target.value});
                break;
            }
            case "title": {
                setInput({...input, title: event.target.value});
                break;
            }
            case "subTitle": {
                setInput({...input, subTitle: event.target.value})
                break;
            }

        }
    }
    const HandlerEdit = id => {
        let singleMaterial = materials.find((x) => x.id === id);
        setInput({
            id_menu: singleMaterial.id_menu,
            title: singleMaterial.title,
            subTitle: singleMaterial.subTitle,
            content: singleMaterial.content,
        });
        setSelectedId(id);
        setStatusForm("Edit");
    };

    const handleSubmit = (event) => {
        // menahan submit
        event.preventDefault();

        const params = new URLSearchParams()
        params.append('id_menu', input.id_menu)
        params.append('title', input.title)
        params.append('sub_title', input.subTitle)
        params.append('content', input.content)

        if (statusForm === "Create") {
            axios
                .post(`https://ayobogabackend.herokuapp.com/api/v1/admin/materials`, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization' : `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setMaterials([...materials, {id: res.data.id, ...input}]);
                    window.location.reload();
                }).catch(err => console.log(err));
        } else if (statusForm === 'Edit') {
            axios.put(`https://ayobogabackend.herokuapp.com/api/v1/admin/materials/update/${selectedId}`, params,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : `Bearer ${token}`
                }
            }).then((res) => {
                setMaterials([...materials, {id: res.data.id, ...input}]);
                window.location.reload();
            }).catch(err => console.log(err));
        }
    }

    const HandlerDelete = id => {
        let newMaterial = materials.filter(el => el.id !== id)
        axios.delete(`https://ayobogabackend.herokuapp.com/api/v1/admin/materials/delete/${id}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
            .then(res => {
            }).catch(err => console.log(err));
        setMaterials([...newMaterial])
    }

    console.log(input)

    function submitSearch(e) {
        e.preventDefault()
        axios
            .get(
                `https://ayobogabackend.herokuapp.com/api/v1/visitor/materials`
            )
            .then((res) => {
                let resMaterial = res.data.data.map((el) => {
                    return {
                        id: el.ID,
                        id_menu: el.IDMenu,
                        title: el.Title,
                        subTitle: el.SubTitle,
                        content: el.Content,
                    };
                })
                let filterMaterial = resMaterial.filter(
                    (x) => x.content.toLowerCase().indexOf(search.toLowerCase()) !== -1
                );
                setMaterials([...filterMaterial]);
            })
    }

    function handleChangeSearch(event) {
        setSearch(event.target.value);
    }

    return (

        <>
        <NavDashboard/>
        <div className="text-center mt-3">
            <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
            <h3 className="mt-3">Manage Materi Ayoboga</h3>
        </div>

        <form className="col-md-7 my-4" onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group">
                <label className="form-label" htmlFor="id_menu">Menu</label>
                <select className="form-control"  id="id_menu" name="id_menu" onChange={handleChange} value={input.id_menu}>
                    <option>-- Pilih Menu Materi --</option>
                    {
                        cooks && cooks.map(menus =>  <option value={menus.id}>{menus.title}</option>)
                    }
                </select>
            </div>
            <div className="form-group">
                <label className="form-label" htmlFor="judul">Judul</label>
                <input type="text" className="form-control" id="judul" name="title"
                       value={input.title} aria-describedby="slug" onChange={handleChange}
                       placeholder="Masukin Judul Materi.."/>
            </div>
            <div className="form-group">
                <label className="form-label" >Sub Judul</label>
                <input type="text" className="form-control" name="subTitle"
                       value={input.subTitle} onChange={handleChange}
                       placeholder="Masukin Sub Judul materi.."/>
            </div>
            <div className="form-group">
                <label className="form-label" htmlFor="content">Konten</label>
                <CKEditor

                    editor={ ClassicEditor }
                    data={input.content}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={
                        (event, editor) => {
                            setInput({...input, content: editor.getData()})
                        }
                    }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
            <button type="submit" className="btn btn-primary">{statusForm}</button>
        </form>

        <form className="col-md-7 my-4 mt-5" encType="multipart/form-data" onSubmit={submitSearch}>
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Cari berdasarkan Konten"
                       aria-label="Cari berdasarkan Pertanyaan" aria-describedby="button-addon2" onChange={handleChangeSearch}/>
            </div>
        </form>

        <table className="table table-striped table-hover mt-4">
            <tr>
                <th scope="col">No</th>
                <th scope="col">Menu</th>
                <th scope="col">Judul</th>
                <th scope="col">Sub Judul</th>
                <th scope="col">Konten</th>
                <th scope="col">Aksi</th>
            </tr>
            {
                materials && materials.map((materi, index) => (
                    <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{materi.id_menu}</td>
                        <td>{materi.title}</td>
                        <td>{materi.subTitle}</td>
                        <td>{materi.content.substring(0, 50)}</td>
                        <td>
                            <button className="btn btn-sm btn-primary m-1" onClick={() =>  HandlerEdit(materi.id)}>Edit</button>
                            <button className="btn btn-sm btn-danger m-1" onClick={() => {
                                alert('Hapus ?')
                                HandlerDelete(materi.id)
                            }}>Hapus</button>
                        </td>
                    </tr>
                ))
            }
        </table>

    </>
    )
}
