import React, { Component } from "react";
import logo from "../logo.png";

class ForOFor extends Component {
    render() {
        return (
            <div>
                <div className="text-center mb-3">
                    <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
                    <h3 className="mt-3">Halaman Engga ditemukan :(</h3>
                    <p>Hi! Halaman ini engga ditemukan, coba kembali ke halaman utama :D</p>
                </div>
            </div>
        );
    }
}

export default ForOFor;
