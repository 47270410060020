import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Route, NavLink, HashRouter, Switch} from "react-router-dom";
import Home from "./pages/Home";
import Tentang from "./pages/Tentang";
import Belajar from "./pages/Belajar";
import Moment from "moment";
import ForOFor from "./pages/ForOFor";
import Learn from "./pages/Learn";
import Quiz from "./pages/Quiz";
import Login from "./pages/auth/Login";
import {Dashboard} from "./pages/dashboard/Dashboard";
import {News} from "./pages/dashboard/News";
import {Menus} from "./pages/dashboard/Menus";
import {Materials} from "./pages/dashboard/Materials";
import {Quizzes} from "./pages/dashboard/Quizzes";
import Privasi from "./pages/Privasi";

export const CheckAuth = () => {
    const token = JSON.parse(localStorage.getItem('token'))
    return token !== null && token.expiresAt > new Date().getTime();

}


function Main() {
    return (
        <HashRouter>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12">
                        <div className="my-5 text-center">
                            <NavLink className="mx-2 btn btn-warning" to="/">Home</NavLink>
                            <NavLink to="/belajar" className="mx-2 btn btn-warning">Belajar</NavLink>
                            <NavLink className="mx-2 btn btn-warning" to="/tentang">Tentang</NavLink>
                        </div>
                        <div className="content">
                            <Switch>
                                <Route exact path="/" component={Home}/>
                                <Route path="/belajar" exact component={Belajar}/>
                                <Route path="/learn/:slug" exact component={Learn}/>
                                <Route path="/quiz/:slug" exact component={Quiz}/>
                                <Route path="/tentang" exact component={Tentang}/>
                                <Route path="/privacy-policy" exact component={Privasi}/>
                                {
                                    CheckAuth() ? (
                                        <>
                                            <Route path="/dashboard" exact component={Dashboard}/>
                                            <Route path="/dashboard/news" exact component={News}/>
                                            <Route path="/dashboard/menus" exact component={Menus}/>
                                            <Route path="/dashboard/materials" exact component={Materials}/>
                                            <Route path="/dashboard/quizzes" exact component={Quizzes}/>
                                        </>
                                        ) :
                                        <Route path="/login" exact component={Login}/>
                                }
                                <Route component={ForOFor}/>
                            </Switch>
                        </div>
                    </div>
                    <div className="col-md-12 my-3 text-center text-warning">
                        {/* eslint-disable-next-line no-undef */}
                        <p><b>© 2019 - {Moment().format('YYYY')} Ayoboga By <a className="text-warning" href={"https://abercode-software.com"} target={"_blank"}><u>Abercode Software</u></a><br/>All Rights Reserved</b></p>
                    </div>
                </div>
            </div>
        </HashRouter>
    );
}

export default Main;
