import logo from "../../logo.png";
import React, {useEffect, useState} from "react";
import NavDashboard from "../../components/NavDashboard";
import axios from "axios";
import Moment from "moment";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export const News = () => {
    const [news, setNews] = useState(null);
    const [input, setInput] = useState({
        title: null,
        text: null,
    });
    const [statusForm, setStatusForm] = useState("Create");
    const [selectedId, setSelectedId] = useState(null);
    const [search, setSearch] = useState(null);

    const token = JSON.parse(localStorage.getItem("JWT"))

    useEffect(() => {

        if (news === null) {
            axios
                .get("https://ayobogabackend.herokuapp.com/api/v1/visitor/news")
                .then((res) => {
                    setNews(
                        res.data.data.map((el) => {
                            return {
                                id: el.ID,
                                createdAt: el.CreatedAt,
                                title: el.Title,
                                text: el.Text,
                            };
                        })
                    );
                });
        }
    }, [news]);

    const handleChange = (event) => {
        let typeOfInput = event.target.name;

        // eslint-disable-next-line default-case
        switch (typeOfInput) {
            case "title": {
                setInput({...input, title: event.target.value});
                break;
            }
        }
    }
    const HandlerEdit = id => {
        let singleNews = news.find((x) => x.id === id);
        setInput({
            title: singleNews.title,
            text: singleNews.text,
        });
        setSelectedId(id);
        setStatusForm("Edit");
        console.log(input)
    };


    const handleSubmit = (event) => {
        // menahan submit
        event.preventDefault();

        const params = new URLSearchParams()
        params.append('title', input.title)
        params.append('text', input.text)

        if (statusForm === "Create") {
            axios
                .post(`https://ayobogabackend.herokuapp.com/api/v1/admin/news`, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization' : `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setNews([...news, {id: res.data.id, ...input}]);
                    window.location.reload();
                }).catch(err => console.log(err));
        } else if (statusForm === 'Edit') {
            axios.put(`https://ayobogabackend.herokuapp.com/api/v1/admin/news/update/${selectedId}`, params,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : `Bearer ${token}`
                }
            }).then((res) => {
                setNews([...news, {id: res.data.id, ...input}]);
                window.location.reload();
            }).catch(err => console.log(err));
        }
    }

    console.log(input)

    const HandlerDelete = id => {
        let newNews = news.filter(el => el.id !== id)
        axios.delete(`https://ayobogabackend.herokuapp.com/api/v1/admin/news/delete/${id}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
            .then(res => {
            }).catch(err => console.log(err));
        setNews([...newNews])
    }


    function submitSearch(e) {
        e.preventDefault()
            axios.get("https://ayobogabackend.herokuapp.com/api/v1/visitor/news")
            .then((res) => {
                let restNews = res.data.data.map((el) => {
                        return {
                            id: el.ID,
                            createdAt: el.CreatedAt,
                            title: el.Title,
                            text: el.Text,
                        };
                    })
                let filterNews= restNews.filter(
                    (x) => x.title.toLowerCase().indexOf(search.toLowerCase()) !== -1
                );
                setNews([...filterNews]);
            })
    }

    function handleChangeSearch(event) {
        setSearch(event.target.value);
    }


    return (
        <>
        <NavDashboard/>
        <div className="text-center mt-3">
            <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
            <h3 className="mt-3">Manage Berita Ayoboga</h3>
        </div>
        <form className="col-md-7 my-4" onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group">
                <label htmlFor="judul">Judul Berita</label>
                <input type="text" className="form-control" id="judul" name="title"
                       value={input.title} onChange={handleChange}
                       placeholder="Masukin judul Beritanya.."/>
            </div>
            <div className="form-group">
                <label htmlFor="content">Konten</label>
                <CKEditor

                    editor={ ClassicEditor }
                    data={input.text}
                    onReady={ editor => {
                        // You can store the "editor" and use when it is needed.
                        console.log( 'Editor is ready to use!', editor );
                    } }
                    onChange={
                        (event, editor) => {
                            setInput({...input, text: editor.getData()})
                        }
                    }
                    onBlur={ ( event, editor ) => {
                        console.log( 'Blur.', editor );
                    } }
                    onFocus={ ( event, editor ) => {
                        console.log( 'Focus.', editor );
                    } }
                />
            </div>
            <button type="submit" className="btn btn-primary">{statusForm}</button>
        </form>
            <form className="col-md-7 my-4 mt-5" encType="multipart/form-data" onSubmit={submitSearch}>
                <div className="input-group mb-3">
                    <input type="text" className="form-control" placeholder="Cari berdasarkan Pertanyaan"
                           aria-label="Cari berdasarkan Judul" aria-describedby="button-addon2" onChange={handleChangeSearch}/>
                </div>
            </form>
        <table className="table table-striped table-hover mt-4">
            <tr>
                <th scope="col">No</th>
                <th scope="col">Judul</th>
                <th scope="col">Kontent</th>
                <th scope="col">Tanggal Dibuat</th>
                <th scope="col">Aksi</th>
            </tr>
            {
                news && news.map((berita, index) => (
                    <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{berita.title}</td>
                        <td>{berita.text.substring(0, 50)}</td>
                        <td>{Moment(berita.createdAt).startOf("day").fromNow()}</td>
                        <td>
                            <button className="btn btn-sm btn-primary m-1" onClick={() => {
                                HandlerEdit(berita.id)
                            }}>Edit</button>
                            <button className="btn btn-sm btn-danger m-1" onClick={() => {
                                alert('Hapus ?')
                                HandlerDelete(berita.id)
                            }}>Hapus</button>
                        </td>
                    </tr>
                ))
            }
        </table>

    </>
    )
}

