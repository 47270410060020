import React, { Component } from "react";
import logo from "../logo.png";

class Tentang extends Component {
    render() {
        return (
            <div className="text-warning">
                <div className="text-center mb-3 text-warning">
                    <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
                    <h3 className="mt-3">Tentang Ayoboga</h3>
                    <p>Makhluk apa itu ayoboga?</p>
                </div>
                <p>Ayoboga adalah website pembelajaran online tentang jasa boga, Bahasa<br/>Indonesia. Tetap Belajar Masak untuk Investasi Di masa depan~!</p>
                <p>Kontak Email: support@ayoboga.com</p>
                <p>Versi Aplikasi   : Versi Ayoboga App v.1.0</p>
            </div>
        );
    }
}

export default Tentang;
