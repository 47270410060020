import React, {useEffect, useState} from "react";


function CardQuiz({item, index, id})  {
    const [checked, setChecked] = useState(localStorage.getItem(`checked-${id}`) || null);

    useEffect(() => {
        localStorage.setItem(`checked-${id}`,checked )
    })

    return (
        <div>
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="my-3 shadow p-3 rounded-lg" key={index}>
                        <div className="card-header">
                            <h5>{item.question}</h5>
                            <p className="text-muted">{item.desc}</p>
                        </div>
                        <div className="card-body">
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name={item.question} checked={checked === item.answer_a}
                                       id={item.answer_a} onClick={() => setChecked(item.answer_a)}/>
                                    <label className="form-check-label" htmlFor={item.answer_a}>
                                        {item.answer_a}
                                    </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name={item.question} checked={checked === item.answer_b}
                                       id={item.answer_b} onClick={() => setChecked(item.answer_b)}/>
                                <label className="form-check-label" htmlFor={item.answer_b}>
                                    {item.answer_b}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name={item.question} checked={checked === item.answer_c}
                                       id={item.answer_c} onClick={() => setChecked(item.answer_c)}/>
                                <label className="form-check-label" htmlFor={item.answer_c}>
                                    {item.answer_c}
                                </label>
                            </div>
                            <div className="form-check">
                                <input className="form-check-input" type="radio" name={item.question} checked={checked === item.answer_d}
                                       id={item.answer_d} onClick={() => setChecked(item.answer_d)}/>
                                <label className="form-check-label" htmlFor={item.answer_d}>
                                    {item.answer_d}
                                </label>
                            </div>

                            <div className="mt-3">
                                {
                                    checked === item.correctAnswer ?
                                        <>
                                            <div className="alert alert-success" role="alert">
                                                <p><b>Jawaban kamu Benar!</b></p>
                                                <p>{item.explanationAnswer}</p>

                                            </div>

                                        </>
                                        : checked === null ?
                                        <div className="alert alert-primary" role="alert">
                                            Silahkan pilih jawabannya :D
                                        </div> :
                                        <div className="alert alert-danger" role="alert">
                                            Jawaban kamu Salah :(
                                        </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CardQuiz;
