import React, { useState, useEffect } from "react";
import logo from "../logo.png";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import Moment from "moment";
import { CheckAuth } from "../Main";

function Home() {
  const [news, setNews] = useState(null);
  const [profile, setProfile] = useState(null);

  useEffect(() => {
    if (profile === null) {
      setProfile(JSON.parse(localStorage.getItem("user")));
    }

    if (news === null) {
      axios
        .get("https://api-cms.ayoboga.com/news?size=5")
        .then((res) => {
            if (!res.data.error) {
                console.log(res.data.results.content)
              setNews(
                  res.data.results.content.map((el) => {
                  return {
                    id: el.id,
                    createdAt: el.createdAt,
                    title: el.name,
                    text: el.description,
                    imageUrl : el.image
                  };
                })
              );
            }
        });
    }
  }, [news, profile]);
  return (
    <div>
      <div className="text-center text-warning">
        <img src={logo} alt="Logo Ayoboga Education" width={100} height={100} />
        <h3 className="mt-3">
          Hi! {CheckAuth() && profile !== null ? profile.name : "Kamu"}
          <br /> Selamat Datang di Lobby Ayoboga
        </h3>
        <p>Informasi Seputar Ayoboga</p>
      </div>

      <div className="mx-3 my-4 row justify-content-center">
          {news &&
              news.map((newsItem, index) => (
                  <div className="my-3 shadow p-3 rounded-lg col-md-7 bg-warning"
                       style={{color:'#0C1086'}} key={index}>
                      <div className="card-header">
                          <img src={newsItem?.imageUrl} alt={newsItem?.title} className="rounded-lg img-fluid news-image" />
                          <h5 className="mt-3">{newsItem?.title}</h5>
                          <p className="text-muted">
                              {Moment(newsItem?.createdAt)
                                  ?.lang("id")
                                  .format("MMMM Do YYYY, h:mm:ss A")}
                          </p>
                      </div>
                      <p className="card-text mt-3">{ReactHtmlParser(newsItem?.text)}</p>
                  </div>
              ))}
      </div>
    </div>
  );
}

export default Home;
