import React, {useEffect, useState} from "react";
import axios from "axios";
import CardQuiz from "../components/CardQuiz";
import {NavLink} from "react-router-dom";

function Quiz({match})  {
    const [questions, setQuestion] = useState(null);

    useEffect(() => {
        if (questions === null) {
            axios
                .get(
                    `https://ayobogabackend.herokuapp.com/api/v1/visitor/quiz/${match.params.slug}`
                )
                .then((res) => {
                    setQuestion(
                        res.data.questions.map((el) => {
                            return {
                                id: el.ID,
                                question: el.Question,
                                desc: el.Desc,
                                answer_a: el.AnswerA,
                                answer_b: el.AnswerB,
                                answer_c: el.AnswerC,
                                answer_d: el.AnswerD,
                                correctAnswer: el.CorrectAnswer,
                                explanationAnswer: el.ExplanationAnswer
                            };
                        })
                    )
                })
        }

    }, [match.params.slug, questions]);


    return (
        <div className="row justify-content-center">
               <div className="col-md-7">
                   {
                       questions && questions.map((item, index) => (
                           <CardQuiz item={item} index={index} id={item.id} />
                       ))
                   }
                   <h5 className="mt-5">Ada yang lupa sama materinya? Atau selesai?</h5>
                   <NavLink to={`/learn/${match.params.slug}`} className="btn btn-primary">Kembali lagi yu!</NavLink>
               </div>
        </div>
    );
}

export default Quiz;
