import React from "react";
import logo from "../../logo.png";
import SocialButton from "../../components/SocialButton";
const sign = require('jwt-encode');

const JWT_SECRET="eezvfdnbzpvmcgbpttqp"


function Login () {

    const onSuccess = response => {

        let exp =  new Date();
        exp.setDate(new Date().getDate()+3);


        let decode = {
            "user_id": response.profile.id,
            "exp":     Math.round(exp/1000),
            "iat":     Math.round(new Date(Date.now())/1000),
        }

        let jwtToken = sign(decode, JWT_SECRET)
        localStorage.setItem("JWT", JSON.stringify(jwtToken));
        localStorage.setItem("user", JSON.stringify(response.profile))
        localStorage.setItem("token", JSON.stringify(response.token))
        window.location.href="/"
    };
    const onFailure = response => console.error(response);
    return (
        <div>
            <div className="text-center">
                <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
                <h3 className="mt-3">Hi! Admin Ayoboga</h3>
                <p>Selamat datang di Login Area</p>
                <div className="mt-5">
                    <p>Kamu bisa Login pake </p>
                    <SocialButton
                        provider='google'
                        appId='9123106187-alvib1276usnvva966asve43o1ovt9ll.apps.googleusercontent.com'
                        onLoginSuccess={onSuccess}
                        onLoginFailure={onFailure}
                    >Login Dengan Google</SocialButton>
                </div>
            </div>
        </div>
    );
}

export default Login;
