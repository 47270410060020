import React, {useEffect, useState} from "react";
import axios from "axios";
import ReactHtmlParser from "react-html-parser";
import {NavLink} from "react-router-dom";


function Learn({match})  {
    const [materials, setMaterials] = useState(null);

    useEffect(() => {
        if (materials === null) {
            axios
                .get(
                    `https://api-cms.ayoboga.com/materials/${match.params.slug}/parent`
                )
                .then((res) => {
                    setMaterials(
                        res.data.results.content.map((el) => {
                            return {
                                id: el.id,
                                title: el.name,
                                content: el.description,
                                imageUrl: el.image
                            };
                        })
                    )
                })
        }

    }, [match.params.slug, materials]);

    console.log(match)

    return (
        <div>
            <div className="row justify-content-center">
                <div className="my-3 col-md-7">
                    {materials && materials.map((material, index) => (
                        <div className="my-3 shadow p-3 rounded-lg bg-warning"  style={{color:'#0C1086'}} key={index}>
                            <div className="card-header">
                                <img src={material?.imageUrl} alt={material?.title} className="rounded-lg img-fluid news-image" />
                                <h5 className="text-capitalize mt-3">{material.title}</h5>
                            </div>

                            <p className="card-text mt-3">{ReactHtmlParser(material.content)}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Learn;
