import React, {useEffect, useState} from "react";
import axios from "axios";
import logo from "../logo.png";
import {NavLink} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";

function Belajar()  {
    const [cooks, setCooks] = useState(null);

    useEffect(() => {
        if (cooks === null) {
            axios
                .get("https://api-cms.ayoboga.com/master-materials")
                .then((res) => {
                    console.log(res.data.results.content)
                    setCooks(
                        res.data.results.content.map((el) => {
                            return {
                                id: el.id,
                                title: el.name,
                                desc: el.description,
                                slug: el.id,
                                imageUrl: el.image
                            };
                        })
                    );
                });
        }
    }, [cooks]);
    return (
        <div>
            <div className="text-center text-warning">
                <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
                <h3 className="mt-3">Hi! Kita mulai Belajar <br/>di Ayoboga</h3>
                <p>Dapati ilmu, sebarkan ilmu dan manjadi lebih baik.</p>
            </div>

            <div className="row justify-content-center">
                <div className="my-3 col-md-7">
                    {cooks && cooks.map((cook, index) => (
                        <div className="my-3 shadow p-3 rounded-lg bg-warning" style={{color:'#0C1086'}} key={index}>
                                <img src={cook?.imageUrl} alt={cook?.title} className="rounded-lg img-fluid news-image" />
                                <h5 className="text-capitalize mt-3">{cook.title}</h5>
                            <p className="card-text mt-3">{ReactHtmlParser(cook.desc)}</p>
                            <div>
                                <NavLink to={`learn/` + cook.slug} className="btn text-warning" style={{backgroundColor:'#0C1086'}}>Belajar sekarang</NavLink>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Belajar;
