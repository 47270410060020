import logo from "../../logo.png";
import React, {useEffect, useState} from "react";
import NavDashboard from "../../components/NavDashboard";
import axios from "axios";


export const Quizzes = () => {
    const [questions, setQuestion] = useState(null);
    const [cooks, setCooks] = useState(null);
    const [input, setInput] = useState({
        id_menu: null,
        question: null,
        desc: null,
        answer_a: null,
        answer_b: null,
        answer_c: null,
        answer_d: null,
        correctAnswer: null,
        explanationAnswer: null,
    });
    const [statusForm, setStatusForm] = useState("Create");
    const [selectedId, setSelectedId] = useState(null);
    const [search, setSearch] = useState(null);

    const token = JSON.parse(localStorage.getItem("JWT"))

    useEffect(() => {
        if (questions === null) {
            axios
                .get(
                    `https://ayobogabackend.herokuapp.com/api/v1/visitor/questions`
                )
                .then((res) => {
                    setQuestion(
                        res.data.data.map((el) => {
                            return {
                                id: el.ID,
                                id_menu: el.IDMenu,
                                question: el.Question,
                                desc: el.Desc,
                                answer_a: el.AnswerA,
                                answer_b: el.AnswerB,
                                answer_c: el.AnswerC,
                                answer_d: el.AnswerD,
                                correctAnswer: el.CorrectAnswer,
                                explanationAnswer: el.ExplanationAnswer
                            };
                        })
                    )
                })
        }
        if (cooks === null) {
            axios
                .get("https://ayobogabackend.herokuapp.com/api/v1/visitor/menus")
                .then((res) => {
                    setCooks(
                        res.data.data.map((el) => {
                            return {
                                id: el.ID,
                                title: el.Title,
                                desc: el.Desc,
                                slug: el.Slug,
                            };
                        })
                    );
                });
        }

    }, [cooks, questions]);

    const handleChange = (event) => {
        let typeOfInput = event.target.name;

        // eslint-disable-next-line default-case
        switch (typeOfInput) {
            case "id_menu": {
                setInput({...input, id_menu: event.target.value});
                break;
            }
            case "question": {
                setInput({...input, question: event.target.value})
                break;
            }
            case "desc": {
                setInput({...input, desc: event.target.value});
                break;
            }
            case "answer_a": {
                setInput({...input, answer_a: event.target.value})
                break;
            }
            case "answer_b": {
                setInput({...input, answer_b: event.target.value});
                break;
            }
            case "answer_c": {
                setInput({...input, answer_c: event.target.value})
                break;
            }
            case "answer_d": {
                setInput({...input, answer_d: event.target.value});
                break;
            }
            case "correctAnswer": {
                setInput({...input, correctAnswer: event.target.value})
                break;
            }
            case "explanationAnswer": {
                setInput({...input, explanationAnswer: event.target.value});
                break;
            }
        }
    }
    const HandlerEdit = id => {
        let singleQuiz = questions.find((x) => x.id === id);
        setInput({
            id_menu: singleQuiz.id_menu,
            question: singleQuiz.question,
            desc: singleQuiz.desc,
            answer_a: singleQuiz.answer_a,
            answer_b: singleQuiz.answer_b,
            answer_c: singleQuiz.answer_c,
            answer_d: singleQuiz.answer_d,
            correctAnswer: singleQuiz.correctAnswer,
            explanationAnswer: singleQuiz.explanationAnswer
        });
        setSelectedId(id);
        setStatusForm("Edit");
    };
    const handleChangeSearch = (event) => {
        setSearch(event.target.value);
    };

    const submitSearch = (e) => {
        e.preventDefault()
        axios
            .get(
                `https://ayobogabackend.herokuapp.com/api/v1/visitor/questions`
            )
            .then((res) => {
                let resQuiz = res.data.data.map((el) => {
                        return {
                            id: el.ID,
                            id_menu: el.IDMenu,
                            question: el.Question,
                            desc: el.Desc,
                            answer_a: el.AnswerA,
                            answer_b: el.AnswerB,
                            answer_c: el.AnswerC,
                            answer_d: el.AnswerD,
                            correctAnswer: el.CorrectAnswer,
                            explanationAnswer: el.ExplanationAnswer
                        };
                    })
                let filterQuiz = resQuiz.filter(
                    (x) => x.question.toLowerCase().indexOf(search.toLowerCase()) !== -1
                );
                setQuestion([...filterQuiz]);
            })
    }


    const handleSubmit = (event) => {
        // menahan submit
        event.preventDefault();

        const params = new URLSearchParams()
        params.append('menuID', input.id_menu)
        params.append('question', input.question)
        params.append('desc', input.desc)
        params.append('answerA', input.answer_a)
        params.append('answerB', input.answer_b)
        params.append('answerC', input.answer_c)
        params.append('answerD', input.answer_d)
        params.append('correctAnswer', input.correctAnswer)
        params.append('explanationAnswer', input.explanationAnswer)

        if (statusForm === "Create") {
            axios
                .post(`https://ayobogabackend.herokuapp.com/api/v1/admin/question`, params, {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization' : `Bearer ${token}`
                    }
                })
                .then((res) => {
                    setQuestion([...questions, {id: res.data.id, ...input}]);
                    window.location.reload();
                }).catch(err => console.log(err));
        } else if (statusForm === 'Edit') {
            axios.put(`https://ayobogabackend.herokuapp.com/api/v1/admin/question/update/${selectedId}`, params,{
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization' : `Bearer ${token}`
                }
            }).then((res) => {
                setQuestion([...questions, {id: res.data.id, ...input}]);
                window.location.reload();
            }).catch(err => console.log(err));
        }
    }

    const HandlerDelete = id => {
        let newQuestion = questions.filter(el => el.id !== id)
        axios.delete(`https://ayobogabackend.herokuapp.com/api/v1/admin/question/delete/${id}`, {
            headers: {
                'Authorization' : `Bearer ${token}`
            }
        })
            .then(res => {
                setQuestion([...newQuestion])
            }).catch(err => console.log(err));
    }

    return (
        <>
        <NavDashboard/>
        <div className="text-center mt-3">
            <img src={logo}  alt="Logo Ayoboga Education" width={100} height={100}/>
            <h3 className="mt-3">Manage Quiz Ayoboga</h3>
        </div>

        <form className="col-md-7 my-4" onSubmit={handleSubmit} encType="multipart/form-data">
            <div className="form-group">
                <label className="form-label" htmlFor="id_menu">Menu</label>
                <select className="form-control"  id="id_menu" name="id_menu" onChange={handleChange} value={input.id_menu}>
                    <option>-- Pilih Menu Materi --</option>
                    {
                        cooks && cooks.map(menus =>  <option value={menus.id}>{menus.title}</option>)
                    }
                </select>
            </div>
            <div className="form-group">
                <label className="form-label">Question</label>
                <input type="text" className="form-control"  name="question"
                       value={input.question} onChange={handleChange}
                       placeholder="Masukin Pertanyaan.."/>
            </div>
            <div className="form-group">
                <label className="form-label" >Deksripsi Pertanyaan</label>
                <input type="text" className="form-control" name="desc"
                       value={input.desc} onChange={handleChange}
                       placeholder="Masukin Deskripsi Pertanyaan.."/>
            </div>
           <div className="row">
               <div className="col-md-6">
                   <div className="form-group">
                       <label className="form-label">Jawaban Pertama</label>
                       <input type="text" className="form-control" name="answer_a"
                              value={input.answer_a} onChange={handleChange}
                              placeholder="Masukin Jawaban pertama.."/>
                   </div>
               </div>
               <div className="col-md-6">
                   <div className="form-group">
                       <label className="form-label">Jawaban Kedua</label>
                       <input type="text" className="form-control" name="answer_b"
                              value={input.answer_b} onChange={handleChange}
                              placeholder="Masukin Jawaban kedua.."/>
                   </div>
               </div>
               <div className="col-md-6">
                   <div className="form-group">
                       <label className="form-label">Jawaban Ketiga</label>
                       <input type="text" className="form-control" name="answer_c"
                              value={input.answer_c} onChange={handleChange}
                              placeholder="Masukin Jawaban ketiga.."/>
                   </div>
               </div>
               <div className="col-md-6">
                   <div className="form-group">
                       <label className="form-label">Jawaban keempat</label>
                       <input type="text" className="form-control" name="answer_d"
                              value={input.answer_d} onChange={handleChange}
                              placeholder="Masukin Jawaban keempat.."/>
                   </div>
               </div>
           </div>
            <div className="form-group">
                <label className="form-label" htmlFor="Question">Jawaban Yang benar</label>
                <input type="text" className="form-control" id="Question" name="correctAnswer"
                       value={input.correctAnswer} onChange={handleChange}
                       placeholder="Masukin Jawaban yang benar (Salin 1 dari jawaban).."/>
            </div>
            <div className="form-group">
                <label className="form-label" >Penjelasan Pertanyaan</label>
                <input type="text" className="form-control" name="explanationAnswer"
                       value={input.explanationAnswer} onChange={handleChange}
                       placeholder="Masukin Penjelasan Pertanyaan.."/>
            </div>
            <button type="submit" className="btn btn-primary">{statusForm}</button>
        </form>

        <form className="col-md-7 my-4 mt-5" encType="multipart/form-data" onSubmit={submitSearch}>
            <div className="input-group mb-3">
                <input type="text" className="form-control" placeholder="Cari berdasarkan Pertanyaan"
                       aria-label="Cari berdasarkan Pertanyaan" aria-describedby="button-addon2" onChange={handleChangeSearch}/>
            </div>
        </form>
        <table className="table table-striped table-hover mt-4">
            <tr>
                <th scope="col">No</th>
                <th scope="col">ID Menu</th>
                <th scope="col">Pertanyaan</th>
                <th scope="col">Deskripsi</th>
                <th scope="col">Pilihan A</th>
                <th scope="col">Pilihan B</th>
                <th scope="col">Pilihan C</th>
                <th scope="col">Pilihan D</th>
                <th scope="col">Jawaban Benar</th>
                <th scope="col">Penjelasan</th>
                <th scope="col">Aksi</th>
            </tr>
            {
                questions && questions.map((question, index) => (
                    <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{question.id_menu}</td>
                        <td>{question.question}</td>
                        <td>{question.desc.substring(0, 50)}</td>
                        <td>{question.answer_a}</td>
                        <td>{question.answer_b}</td>
                        <td>{question.answer_c}</td>
                        <td>{question.answer_d}</td>
                        <td>{question.correctAnswer}</td>
                        <td>{question.explanationAnswer.substring(0, 50)}</td>
                        <td>
                            <button className="btn btn-sm btn-primary m-1" onClick={() => HandlerEdit(question.id)}>Edit</button>
                            <button className="btn btn-sm btn-danger m-1" onClick={() => {
                                alert("Hapus?")
                                HandlerDelete(question.id)
                            }}>Hapus</button>
                        </td>
                    </tr>
                ))
            }
        </table>
    </>
    )

}
